var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.place)?_c('banner',{attrs:{"title":"Administrar stands","description":_vm.place.name,"type":"banner--admin","options":{padding: '30px'}}}):_vm._e(),_c('div',{staticClass:"pt-3 px-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-end"},[_c('v-btn',{staticClass:"text--primary text-none elevation-0",attrs:{"color":"secondary","dark":""},on:{"click":function($event){return _vm.openStandModal()}}},[_vm._v(" Crear Stand ")]),_c('v-btn',{staticClass:"white--text text-none elevation-0 ml-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.back}},[_vm._v(" Regresar ")])],1)]),_c('div',{staticClass:"row"},[(_vm.place)?_c('div',{staticClass:"col-12 d-flex justify-center"},[_c('konva-stage',{ref:"stage",attrs:{"config":{ width: _vm.place.planeWidth, height: _vm.place.planeHeight }}},[_c('konva-layer',{ref:"layer"},[_c('konva-image',{attrs:{"config":{ image: _vm.adminPlaneImageUrl }}}),_vm._l((_vm.stands),function(stand){return _c('div',{key:stand.id},[(!stand.cornerType)?_c('konva-group',{attrs:{"config":{
                x: Number(stand.xCoordinate),
                y: Number(stand.yCoordinate),
              },"draggable":true},on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":function($event){return _vm.openStandModal(stand)},"dragend":function($event){return _vm.saveNewCoordinates($event, stand)}}},[_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor * stand.width),
                  height: Number(_vm.factor * stand.depth),
                  fill: 'white',
                  stroke: _vm.place.color,
                }}}),_c('konva-text',{attrs:{"config":{
                  x: Number(_vm.factor * stand.width) / 2 - 25,
                  y: Number(_vm.factor * stand.depth) / 2 - 10,
                  text: stand.code.toUpperCase(),
                  fontSize: 13,
                  fontFamily: 'Roboto',
                  fontStyle: 'bold',
                  fill: '#555',
                  padding: 5,
                  align: 'center',
                }}})],1):_vm._e(),(stand.cornerType === 'SIZQ')?_c('konva-group',{attrs:{"config":{
                x: Number(stand.xCoordinate),
                y: Number(stand.yCoordinate),
              },"draggable":true},on:{"click":function($event){return _vm.openStandModal(stand)},"dragend":function($event){return _vm.saveNewCoordinates($event, stand)}}},[_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor * stand.width),
                  height: Number(_vm.factor * stand.depth),
                  fill: 'white',
                  stroke: _vm.place.color,
                }}}),_c('konva-rect',{attrs:{"config":{
                  x:
                    Number(_vm.factor * stand.width) -
                    Number(_vm.factor * stand.widthDifference),
                  y:
                    Number(_vm.factor * stand.depth) -
                    Number(_vm.factor * stand.depthDifference),
                  width: Number(_vm.factor * stand.widthDifference),
                  height: Number(_vm.factor * stand.depthDifference),
                  fill: 'white',
                  stroke: 'white',
                }}}),_c('konva-rect',{attrs:{"config":{
                  x:
                    Number(_vm.factor * stand.width) -
                    Number(_vm.factor * stand.widthDifference) -
                    2,
                  y:
                    Number(_vm.factor * stand.depth) -
                    Number(_vm.factor * stand.depthDifference),
                  width: 2,
                  height: Number(_vm.factor * stand.depthDifference) + 1,
                  fill: _vm.place.color,
                }}}),_c('konva-rect',{attrs:{"config":{
                  x:
                    Number(_vm.factor * stand.width) -
                    Number(_vm.factor * stand.widthDifference) -
                    2,
                  y:
                    Number(_vm.factor * stand.depth) -
                    Number(_vm.factor * stand.depthDifference) -
                    2,
                  width: Number(_vm.factor * stand.widthDifference) + 3,
                  height: 2,
                  fill: _vm.place.color,
                }}}),_c('konva-text',{attrs:{"config":{
                  x: Number(_vm.factor * stand.width) / 2 - 25,
                  y: 20,
                  text: stand.code.toUpperCase(),
                  fontSize: 13,
                  fontFamily: 'Roboto',
                  fontStyle: 'bold',
                  fill: '#555',
                  padding: 5,
                  align: 'center',
                }}})],1):_vm._e(),(stand.cornerType === 'SDER')?_c('konva-group',{attrs:{"config":{
                x: Number(stand.xCoordinate),
                y: Number(stand.yCoordinate),
              },"draggable":true},on:{"click":function($event){return _vm.openStandModal(stand)},"dragend":function($event){return _vm.saveNewCoordinates($event, stand)}}},[_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor * stand.width),
                  height: Number(_vm.factor * stand.depth),
                  fill: 'white',
                  stroke: _vm.place.color,
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y:
                    Number(_vm.factor * stand.depth) -
                    Number(_vm.factor * stand.depthDifference),
                  width: Number(_vm.factor * stand.widthDifference),
                  height: Number(_vm.factor * stand.depthDifference),
                  fill: 'white',
                  stroke: 'white',
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(_vm.factor * stand.widthDifference),
                  y:
                    Number(_vm.factor * stand.depth) -
                    Number(_vm.factor * stand.depthDifference) -
                    2,
                  width: 2,
                  height: Number(_vm.factor * stand.depthDifference) + 3,
                  fill: _vm.place.color,
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y:
                    Number(_vm.factor * stand.depth) -
                    Number(_vm.factor * stand.depthDifference) -
                    2,
                  width: Number(_vm.factor * stand.widthDifference) + 1,
                  height: 2,
                  fill: _vm.place.color,
                }}}),_c('konva-text',{attrs:{"config":{
                  x: Number(_vm.factor * stand.width) / 2 - 25,
                  y: 20,
                  text: stand.code.toUpperCase(),
                  fontSize: 13,
                  fontFamily: 'Roboto',
                  fontStyle: 'bold',
                  fill: '#555',
                  padding: 5,
                  align: 'center',
                }}})],1):_vm._e(),(stand.cornerType === 'IIZQ')?_c('konva-group',{attrs:{"config":{
                x: Number(stand.xCoordinate),
                y: Number(stand.yCoordinate),
              },"draggable":true},on:{"click":function($event){return _vm.openStandModal(stand)},"dragend":function($event){return _vm.saveNewCoordinates($event, stand)}}},[_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor * stand.width),
                  height: Number(_vm.factor * stand.depth),
                  fill: 'white',
                  stroke: _vm.place.color,
                }}}),_c('konva-rect',{attrs:{"config":{
                  x:
                    Number(_vm.factor * stand.width) -
                    Number(_vm.factor * stand.widthDifference),
                  y: 0,
                  width: Number(_vm.factor * stand.widthDifference),
                  height: Number(_vm.factor * stand.depthDifference),
                  fill: 'white',
                  stroke: 'white',
                }}}),_c('konva-rect',{attrs:{"config":{
                  x:
                    Number(_vm.factor * stand.width) -
                    Number(_vm.factor * stand.widthDifference) -
                    2,
                  y: 0,
                  width: 2,
                  height: Number(_vm.factor * stand.depthDifference) + 2,
                  fill: _vm.place.color,
                }}}),_c('konva-rect',{attrs:{"config":{
                  x:
                    Number(_vm.factor * stand.width) -
                    Number(_vm.factor * stand.widthDifference),
                  y: Number(_vm.factor * stand.depthDifference),
                  width: Number(_vm.factor * stand.widthDifference) + 1,
                  height: 2,
                  fill: _vm.place.color,
                }}}),_c('konva-text',{attrs:{"config":{
                  x: Number(_vm.factor * stand.width) / 2 - 25,
                  y: Number(_vm.factor * stand.depth) / 2 + 20,
                  text: stand.code.toUpperCase(),
                  fontSize: 13,
                  fontFamily: 'Roboto',
                  fontStyle: 'bold',
                  fill: '#555',
                  padding: 5,
                  align: 'center',
                }}})],1):_vm._e(),(stand.cornerType === 'IDER')?_c('konva-group',{attrs:{"config":{
                x: Number(stand.xCoordinate),
                y: Number(stand.yCoordinate),
              },"draggable":true},on:{"click":function($event){return _vm.openStandModal(stand)},"dragend":function($event){return _vm.saveNewCoordinates($event, stand)}}},[_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor * stand.width),
                  height: Number(_vm.factor * stand.depth),
                  fill: 'white',
                  stroke: _vm.place.color,
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor * stand.widthDifference),
                  height: Number(_vm.factor * stand.depthDifference),
                  fill: 'white',
                  stroke: 'white',
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(_vm.factor * stand.widthDifference),
                  y: 0,
                  width: 2,
                  height: Number(_vm.factor * stand.depthDifference),
                  fill: _vm.place.color,
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: Number(_vm.factor * stand.depthDifference),
                  width: Number(_vm.factor * stand.widthDifference) + 2,
                  height: 2,
                  fill: _vm.place.color,
                }}}),_c('konva-text',{attrs:{"config":{
                  x: Number(_vm.factor * stand.width) / 2 - 25,
                  y: Number(_vm.factor * stand.depth) / 2 + 20,
                  text: stand.code.toUpperCase(),
                  fontSize: 13,
                  fontFamily: 'Roboto',
                  fontStyle: 'bold',
                  fill: '#555',
                  padding: 5,
                  align: 'center',
                }}})],1):_vm._e()],1)})],2)],1)],1):_vm._e()]),(_vm.stands)?_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 col-md-10 offset-md-1"},[_c('h3',[_vm._v("Lista de Stands")]),_c('v-simple-table',{staticClass:"mt-2",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" Código ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Valor Pesos (IVA incluido) ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Valor USD (IVA incluido) ")]),_c('th')])]),_c('tbody',_vm._l((_vm.stands),function(stand){return _c('tr',{key:stand.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(stand.code))]),_c('td',{staticClass:"text-center"},[_vm._v(" $"+_vm._s(_vm._f("currency")(stand.copValue + stand.copTax))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("currency")(stand.usdValue + stand.usdTax))+" USD ")]),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openStandModal(stand)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fa fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar stand")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onDeleteStand(stand)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fa fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar stand")])])],1)])}),0)]},proxy:true}],null,false,2041961518)})],1)]):_vm._e(),_c('stand-modal',{ref:"standModal",on:{"onClose":_vm.onClose,"onDeleteStand":function($event){return _vm.onDeleteStand($event)}}})],1),_c('confirm-dialog',{attrs:{"title":"Confirmación","description":"¿Está seguro que desea eliminar este Stand?","is-active":_vm.openConfirmDialog},on:{"onClose":function($event){return _vm.closeConfirmDialog()},"onConfirm":function($event){return _vm.deleteStand()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }