<template>
  <div>
    <banner
      v-if="place"
      title="Administrar stands"
      :description="place.name"
      type="banner--admin"
      :options="{padding: '30px'}">
    </banner>
    <div class="pt-3 px-7">
      <div class="row">
        <div class="col-12 d-flex justify-end">
          <v-btn
            color="secondary"
            class="text--primary text-none elevation-0"
            @click="openStandModal()"
            dark
          >
            Crear Stand
          </v-btn>
          <v-btn
            color="primary"
            class="white--text text-none elevation-0 ml-2"
            @click="back"
            dark
          >
            Regresar
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-center" v-if="place">
          <konva-stage
            ref="stage"
            :config="{ width: place.planeWidth, height: place.planeHeight }"
          >
            <konva-layer ref="layer">
              <konva-image :config="{ image: adminPlaneImageUrl }"/>
              <div v-for="stand in stands" :key="stand.id">
                <!-- SQUARE -->
                <konva-group
                  v-if="!stand.cornerType"
                  :config="{
                  x: Number(stand.xCoordinate),
                  y: Number(stand.yCoordinate),
                }"
                  @mouseenter="handleMouseEnter"
                  @mouseleave="handleMouseLeave"
                  @click="openStandModal(stand)"
                  :draggable="true"
                  @dragend="saveNewCoordinates($event, stand)"
                >
                  <konva-rect
                    :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor * stand.width),
                    height: Number(factor * stand.depth),
                    fill: 'white',
                    stroke: place.color,
                  }"
                  />
                  <konva-text
                    :config="{
                    x: Number(factor * stand.width) / 2 - 25,
                    y: Number(factor * stand.depth) / 2 - 10,
                    text: stand.code.toUpperCase(),
                    fontSize: 13,
                    fontFamily: 'Roboto',
                    fontStyle: 'bold',
                    fill: '#555',
                    padding: 5,
                    align: 'center',
                  }"
                  />
                </konva-group>
                <!-- TOP LEFT -->
                <konva-group
                  v-if="stand.cornerType === 'SIZQ'"
                  :config="{
                  x: Number(stand.xCoordinate),
                  y: Number(stand.yCoordinate),
                }"
                  @click="openStandModal(stand)"
                  :draggable="true"
                  @dragend="saveNewCoordinates($event, stand)"
                >
                  <konva-rect
                    :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor * stand.width),
                    height: Number(factor * stand.depth),
                    fill: 'white',
                    stroke: place.color,
                  }"
                  />
                  <konva-rect
                    :config="{
                    x:
                      Number(factor * stand.width) -
                      Number(factor * stand.widthDifference),
                    y:
                      Number(factor * stand.depth) -
                      Number(factor * stand.depthDifference),
                    width: Number(factor * stand.widthDifference),
                    height: Number(factor * stand.depthDifference),
                    fill: 'white',
                    stroke: 'white',
                  }"
                  />
                  <konva-rect
                    :config="{
                    x:
                      Number(factor * stand.width) -
                      Number(factor * stand.widthDifference) -
                      2,
                    y:
                      Number(factor * stand.depth) -
                      Number(factor * stand.depthDifference),
                    width: 2,
                    height: Number(factor * stand.depthDifference) + 1,
                    fill: place.color,
                  }"
                  />
                  <konva-rect
                    :config="{
                    x:
                      Number(factor * stand.width) -
                      Number(factor * stand.widthDifference) -
                      2,
                    y:
                      Number(factor * stand.depth) -
                      Number(factor * stand.depthDifference) -
                      2,
                    width: Number(factor * stand.widthDifference) + 3,
                    height: 2,
                    fill: place.color,
                  }"
                  />
                  <konva-text
                    :config="{
                    x: Number(factor * stand.width) / 2 - 25,
                    y: 20,
                    text: stand.code.toUpperCase(),
                    fontSize: 13,
                    fontFamily: 'Roboto',
                    fontStyle: 'bold',
                    fill: '#555',
                    padding: 5,
                    align: 'center',
                  }"
                  />
                </konva-group>
                <!-- TOP RIGHT -->
                <konva-group
                  v-if="stand.cornerType === 'SDER'"
                  :config="{
                  x: Number(stand.xCoordinate),
                  y: Number(stand.yCoordinate),
                }"
                  @click="openStandModal(stand)"
                  :draggable="true"
                  @dragend="saveNewCoordinates($event, stand)"
                >
                  <konva-rect
                    :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor * stand.width),
                    height: Number(factor * stand.depth),
                    fill: 'white',
                    stroke: place.color,
                  }"
                  />
                  <konva-rect
                    :config="{
                    x: 0,
                    y:
                      Number(factor * stand.depth) -
                      Number(factor * stand.depthDifference),
                    width: Number(factor * stand.widthDifference),
                    height: Number(factor * stand.depthDifference),
                    fill: 'white',
                    stroke: 'white',
                  }"
                  />
                  <konva-rect
                    :config="{
                    x: Number(factor * stand.widthDifference),
                    y:
                      Number(factor * stand.depth) -
                      Number(factor * stand.depthDifference) -
                      2,
                    width: 2,
                    height: Number(factor * stand.depthDifference) + 3,
                    fill: place.color,
                  }"
                  />
                  <konva-rect
                    :config="{
                    x: 0,
                    y:
                      Number(factor * stand.depth) -
                      Number(factor * stand.depthDifference) -
                      2,
                    width: Number(factor * stand.widthDifference) + 1,
                    height: 2,
                    fill: place.color,
                  }"
                  />
                  <konva-text
                    :config="{
                    x: Number(factor * stand.width) / 2 - 25,
                    y: 20,
                    text: stand.code.toUpperCase(),
                    fontSize: 13,
                    fontFamily: 'Roboto',
                    fontStyle: 'bold',
                    fill: '#555',
                    padding: 5,
                    align: 'center',
                  }"
                  />
                </konva-group>
                <!-- BOTTOM LEFT -->
                <konva-group
                  v-if="stand.cornerType === 'IIZQ'"
                  :config="{
                  x: Number(stand.xCoordinate),
                  y: Number(stand.yCoordinate),
                }"
                  @click="openStandModal(stand)"
                  :draggable="true"
                  @dragend="saveNewCoordinates($event, stand)"
                >
                  <konva-rect
                    :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor * stand.width),
                    height: Number(factor * stand.depth),
                    fill: 'white',
                    stroke: place.color,
                  }"
                  />
                  <konva-rect
                    :config="{
                    x:
                      Number(factor * stand.width) -
                      Number(factor * stand.widthDifference),
                    y: 0,
                    width: Number(factor * stand.widthDifference),
                    height: Number(factor * stand.depthDifference),
                    fill: 'white',
                    stroke: 'white',
                  }"
                  />
                  <konva-rect
                    :config="{
                    x:
                      Number(factor * stand.width) -
                      Number(factor * stand.widthDifference) -
                      2,
                    y: 0,
                    width: 2,
                    height: Number(factor * stand.depthDifference) + 2,
                    fill: place.color,
                  }"
                  />
                  <konva-rect
                    :config="{
                    x:
                      Number(factor * stand.width) -
                      Number(factor * stand.widthDifference),
                    y: Number(factor * stand.depthDifference),
                    width: Number(factor * stand.widthDifference) + 1,
                    height: 2,
                    fill: place.color,
                  }"
                  />
                  <konva-text
                    :config="{
                    x: Number(factor * stand.width) / 2 - 25,
                    y: Number(factor * stand.depth) / 2 + 20,
                    text: stand.code.toUpperCase(),
                    fontSize: 13,
                    fontFamily: 'Roboto',
                    fontStyle: 'bold',
                    fill: '#555',
                    padding: 5,
                    align: 'center',
                  }"
                  />
                </konva-group>
                <!-- BOTTOM RIGHT -->
                <konva-group
                  v-if="stand.cornerType === 'IDER'"
                  :config="{
                  x: Number(stand.xCoordinate),
                  y: Number(stand.yCoordinate),
                }"
                  @click="openStandModal(stand)"
                  :draggable="true"
                  @dragend="saveNewCoordinates($event, stand)"
                >
                  <konva-rect
                    :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor * stand.width),
                    height: Number(factor * stand.depth),
                    fill: 'white',
                    stroke: place.color,
                  }"
                  />
                  <konva-rect
                    :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor * stand.widthDifference),
                    height: Number(factor * stand.depthDifference),
                    fill: 'white',
                    stroke: 'white',
                  }"
                  />
                  <konva-rect
                    :config="{
                    x: Number(factor * stand.widthDifference),
                    y: 0,
                    width: 2,
                    height: Number(factor * stand.depthDifference),
                    fill: place.color,
                  }"
                  />
                  <konva-rect
                    :config="{
                    x: 0,
                    y: Number(factor * stand.depthDifference),
                    width: Number(factor * stand.widthDifference) + 2,
                    height: 2,
                    fill: place.color,
                  }"
                  />
                  <konva-text
                    :config="{
                    x: Number(factor * stand.width) / 2 - 25,
                    y: Number(factor * stand.depth) / 2 + 20,
                    text: stand.code.toUpperCase(),
                    fontSize: 13,
                    fontFamily: 'Roboto',
                    fontStyle: 'bold',
                    fill: '#555',
                    padding: 5,
                    align: 'center',
                  }"
                  />
                </konva-group>
              </div>
            </konva-layer>
          </konva-stage>
        </div>
      </div>
      <div class="row mt-2" v-if="stands">
        <div class="col-12 col-md-10 offset-md-1">
          <h3>Lista de Stands</h3>
          <v-simple-table class="mt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-center">
                  Código
                </th>
                <th class="text-center">
                  Valor Pesos (IVA incluido)
                </th>
                <th class="text-center">
                  Valor USD (IVA incluido)
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="stand in stands"
                :key="stand.id"
              >
                <td class="text-center">{{ stand.code }}</td>
                <td class="text-center">
                  ${{ stand.copValue + stand.copTax | currency }}
                </td>
                <td class="text-center">
                  {{ stand.usdValue + stand.usdTax | currency }} USD
                </td>
                <td class="text-right">

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        class="ma-2 elevation-0"
                        v-bind="attrs"
                        v-on="on"
                        @click="openStandModal(stand)"
                        small
                        color="primary"
                      >
                        <v-icon
                          center
                          dark
                          small
                        >
                          fa fa-edit
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar stand</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        class="ma-2 elevation-0"
                        v-bind="attrs"
                        v-on="on"
                        @click="onDeleteStand(stand)"
                        small
                        color="primary"
                      >
                        <v-icon
                          center
                          dark
                          small
                        >
                          fa fa-trash
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar stand</span>
                  </v-tooltip>

                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
      <stand-modal ref="standModal" @onClose="onClose" @onDeleteStand="onDeleteStand($event)"/>
    </div>

    <confirm-dialog
      title="Confirmación"
      description="¿Está seguro que desea eliminar este Stand?"
      @onClose="closeConfirmDialog()"
      @onConfirm="deleteStand()"
      :is-active="openConfirmDialog"
    />
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import sessionMixin from '@/mixins/sessionMixin'
import { PLACE_URL, STAND_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import StandModal from '@/views/private/stands/components/StandModal'
import Banner from '@/components/banner/Banner'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    Banner,
    StandModal,
    ConfirmDialog
  },
  computed: {
    factor () {
      return 1200 / this.place.size
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getPlace()
      await this.getStands()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  data () {
    return {
      adminPlaneImageUrl: null,
      place: null,
      stands: [],
      openConfirmDialog: false,
      selectedStand: null
    }
  },
  methods: {
    back () {
      this.$router.push({
        name: 'places',
        params: { id: this.place.fairId }
      })
    },

    async getPlace () {
      const response = await this.get(PLACE_URL + '/' + this.$route.params.id)
      this.place = response.data
      const planeImg = new Image()
      planeImg.src =
        process.env.VUE_APP_API_IMAGES_URL + this.place.adminPlaneImageUrl
      planeImg.onload = () => {
        this.adminPlaneImageUrl = planeImg
      }
    },
    async getStands () {
      const response = await this.get(STAND_URL + '/all', {
        params: { placeId: this.$route.params.id }
      })
      this.stands = response.data
    },
    handleMouseEnter (e) {
      const stage = e.target.getStage()
      stage.container().style.cursor = 'pointer'
    },
    handleMouseLeave (e) {
      const stage = e.target.getStage()
      stage.container().style.cursor = 'default'
    },
    onClose () {
      this.showLoader()
      this.getStands()
      this.hideLoader()
    },
    openStandModal (item) {
      this.$refs.standModal.open(item, this.$route.params.id)
    },
    async saveNewCoordinates (event, stand) {
      stand.xCoordinate = event.target.absolutePosition().x
      stand.yCoordinate = event.target.absolutePosition().y
      this.showLoader()
      await this.put(STAND_URL + '/' + stand.id + '/coordinates', stand)
      this.hideLoader()
    },

    onDeleteStand (item) {
      this.selectedStand = item
      this.openConfirmDialog = true
    },

    closeConfirmDialog () {
      this.selectedStand = null
      this.openConfirmDialog = false
    },

    async deleteStand () {
      if (this.selectedStand && this.selectedStand.id) {
        this.showLoader()
        try {
          await this.delete(STAND_URL, this.selectedStand.id)
          await this.getStands()
          this.closeConfirmDialog()
          this.$refs.standModal.close()
          this.hideLoader()
        } catch (e) {
          this.hideLoader()
          if (e && e.response && e.response.status === 404) {
            this.showError('Stand no encontrado')
          } else {
            this.showError()
          }
        }
      }
    }
  },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin],
  name: 'Stands'
}
</script>
